import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import language from "./language";
import { languageList } from "../view/homePage/components/Footer/Footer";
import { preStaticUrl } from "../constants/env_config";
const _lng = (language?.value ?? "en_US").split("-").join("_");
export const lng = languageList.map((v) => v.keyValue).filter((v) => v === _lng)
  .length
  ? _lng
  : "en_US";

export const LngNs = {
  zypher: "zypher",
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en_US",
    backend: {
      loadPath: "/i18n/{{lng}}/{{ns}}.json",
    },
    lng,
    ns: Object.values(LngNs),
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
